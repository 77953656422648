define('ember-content-placeholders/components/content-placeholders-img', ['exports', 'ember-content-placeholders/components/content-placeholders-base', 'ember-content-placeholders/templates/components/content-placeholders-img'], function (exports, _contentPlaceholdersBase, _contentPlaceholdersImg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contentPlaceholdersBase.default.extend({
    className: 'ember-content-placeholders-img',
    classNameBindings: ['className'],
    layout: _contentPlaceholdersImg.default
  });
});