define('ember-content-placeholders/components/content-placeholders-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rounded: false,
    centered: false,
    animated: true,

    classNameBindings: ['rounded:ember-content-placeholders-is-rounded', 'animated:ember-content-placeholders-is-animated', 'centered:ember-content-placeholders-is-centered']
  });
});