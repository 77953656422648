define("accounting/helpers/format-money", ["exports", "accounting/format-money"], function (_exports, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (params, options) {
    var value = params[0];
    return (0, _formatMoney.default)(value, options);
  });
});