define("ember-yeti-table/components/yeti-table/tfoot/row/cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "q7hfuyZ8",
    "block": "{\"symbols\":[\"@theme\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"visible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"td\"],[16,0,[31,[[32,2],\" \",[32,1,[\"tfootCell\"]]]]],[17,3],[12],[2,\"\\n    \"],[18,4,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/tfoot/row/cell/template.hbs"
    }
  });
});