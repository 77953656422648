define('ember-cli-bootstrap3-tooltip/components/twbs-cite', ['exports', 'ember-cli-bootstrap3-tooltip/components/twbs-span'], function (exports, _twbsSpan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _twbsSpan.default.extend({
    classNames: ['twbs-cite'],
    /**
     * This is a `cite` tag.
     */
    tagName: 'cite',
    twbsSpan: false // turns of the style class `twbs-span` inherited from the `twbs-span` component.
  });
});