define('ember-content-placeholders/components/content-placeholders-nav', ['exports', 'ember-content-placeholders/components/content-placeholders-base', 'ember-content-placeholders/templates/components/content-placeholders-nav'], function (exports, _contentPlaceholdersBase, _contentPlaceholdersNav) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contentPlaceholdersBase.default.extend({
    className: 'ember-content-placeholders-nav',
    classNameBindings: ['className'],
    layout: _contentPlaceholdersNav.default
  });
});