(function () {
  Ember.Component.reopen({
    $: function $(sel) {
      (false && !(this.tagName !== '') && Ember.assert("You cannot access this.$() on a component with `tagName: ''` specified.", this.tagName !== ''));
      (false && !(false) && Ember.deprecate('Using this.$() in a component has been deprecated, consider using this.element', false, {
        id: 'ember-views.curly-components.jquery-element',
        until: '4.0.0',
        url: 'https://emberjs.com/deprecations/v3.x#toc_jquery-apis'
      }));
      if (this.element) {
        return sel ? jQuery(sel, this.element) : jQuery(this.element);
      }
    }
  });
})();