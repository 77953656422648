define("ember-yeti-table/-private/utils/create-regex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createRegex;
  var ESCAPE_REGEX = new RegExp('(\\' + ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\', '$', '^', '-'].join('|\\') + ')', 'g');
  function escapeRegex(val) {
    return val.replace(ESCAPE_REGEX, '\\$1');
  }
  function createRegex(search) {
    var regex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var smart = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var caseInsensitive = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    if (Ember.isBlank(search)) {
      return;
    }
    search = regex ? search : escapeRegex(search);
    if (smart) {
      /* For smart filtering we want to allow the search to work regardless of
       * word order. We also want double quoted text to be preserved, so word
       * order is important - a la google. So this is what we want to
       * generate:
       *
       * ^(?=.*?\bone\b)(?=.*?\btwo three\b)(?=.*?\bfour\b).*$
       */
      var words = search.match(/"[^"]+"|[^ ]+/g) || [''];
      var a = words.map(function (word) {
        if (word.charAt(0) === '"') {
          var m = word.match(/^"(.*)"$/);
          word = m ? m[1] : word;
        }
        return word.replace('"', '');
      });
      search = "^(?=.*?".concat(a.join(')(?=.*?'), ").*$");
    }
    return new RegExp(search, caseInsensitive ? 'i' : '');
  }
});