define('ember-cli-bootstrap3-tooltip/components/twbs-span', ['exports', 'ember-cli-bootstrap3-tooltip/templates/components/twbs-span', 'ember-cli-bootstrap3-tooltip/mixins/twbs-tooltip'], function (exports, _twbsSpan, _twbsTooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_twbsTooltip.default, {
    actions: {
      /**
       * Sets the `title` property to the supplied value and the `html?` property to `true`.
       * @param title the title for the span.  Will be rendered as html.
       */
      setElementTitle: function setElementTitle(title) {
        this.set('html?', true);
        this.set('defaultTitle', title);
      }
    },
    classNameBindings: ['twbsSpan'],
    layout: _twbsSpan.default,
    /**
     * This is a `span` tag.
     */
    tagName: 'span',
    twbsSpan: true
  });
});