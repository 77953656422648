define("ember-composable-helpers/helpers/next", ["exports", "ember-composable-helpers/utils/get-index", "ember-composable-helpers/-private/get-value-array-and-use-deep-equal-from-params"], function (_exports, _getIndex, _getValueArrayAndUseDeepEqualFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.next = next;
  function next(currentValue, array) {
    var useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    if (!array) {
      array = [];
    }
    var currentIndex = (0, _getIndex.default)(array, currentValue, useDeepEqual);
    var lastIndex = array.length - 1;
    if (Ember.isEmpty(currentIndex)) {
      return;
    }
    return currentIndex === lastIndex ? currentValue : Ember.A(array).objectAt(currentIndex + 1);
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    var _getValueArrayAndUseD = (0, _getValueArrayAndUseDeepEqualFromParams.default)(params),
      currentValue = _getValueArrayAndUseD.currentValue,
      array = _getValueArrayAndUseD.array,
      useDeepEqual = _getValueArrayAndUseD.useDeepEqual;
    return next(currentValue, array, useDeepEqual);
  });
});