define('ember-content-placeholders/components/content-placeholders', ['exports', 'ember-content-placeholders/templates/components/content-placeholders'], function (exports, _contentPlaceholders) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rounded: false,
    centered: false,
    animated: true,

    layout: _contentPlaceholders.default
  });
});