define('ember-cli-tinymce/components/tinymce-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer,
      on = Ember.on,
      run = Ember.run;
  exports.default = Ember.Component.extend({
    editor: null,
    classNames: ['tinymce-editor'],
    tagName: 'textarea',
    _contentChangedListener: null,
    changeDebounce: 10,
    options: {},

    valueChanged: observer('value', function () {
      var _getProperties = this.getProperties('editor', 'value'),
          editor = _getProperties.editor,
          value = _getProperties.value;

      if (editor && editor.getContent() !== value) {
        editor.setContent(value || '');
      }
    }),

    onValueChanged: function onValueChanged(value) {
      this.set('value', value);
    },
    contentChanged: function contentChanged(editor) {
      var content = editor.getContent();
      if (editor.isDirty() && content !== this.get('value')) {
        this.onValueChanged(editor.getContent());
        editor.setDirty(true);
      }
    },
    debounceContentChanged: function debounceContentChanged(editor, time) {
      run.debounce(this, this.contentChanged, editor, time);
    },


    setEvents: observer('editor', function () {
      var _getProperties2 = this.getProperties('changeDebounce', 'editor'),
          changeDebounce = _getProperties2.changeDebounce,
          editor = _getProperties2.editor;

      if (!editor) {
        return;
      }

      editor.on('change keyup keydown keypress mousedown', run.bind(this, this.debounceContentChanged, editor, changeDebounce));
    }),

    initTiny: on('didInsertElement', observer('options', function () {
      var _this = this;

      var _getProperties3 = this.getProperties('options', 'editor'),
          options = _getProperties3.options,
          editor = _getProperties3.editor;

      var initFunction = function initFunction(editor) {
        _this.set('editor', editor);
        _this.get('editor').setContent(_this.get('value') || ''); //Set content with default text
      };

      var customOptions = {
        selector: '#' + this.get('elementId'),
        init_instance_callback: run.bind(this, initFunction)
      };

      if (editor) {
        editor.setContent('');
        editor.destroy();
      }

      run.later(function () {
        if (typeof tinymce === 'undefined') {
          return;
        }
        tinymce.init(Ember.assign({}, options, customOptions));
      }, 10);
    })),

    cleanUp: on('willDestroyElement', function () {
      var editor = this.get('editor');
      if (editor) {
        editor.off('change keyup keydown keypress mousedown');
        editor.destroy();
      }
    })
  });
});