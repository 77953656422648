define("ember-yeti-table/-private/utils/filtering-utils", ["exports", "ember-yeti-table/-private/utils/create-regex"], function (_exports, _createRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterData;
  function createColumnFilters(columns) {
    var searcheableColumns = columns.filter(function (c) {
      return !Ember.isEmpty(Ember.get(c, 'filter')) || !Ember.isEmpty(Ember.get(c, 'filterFunction'));
    });
    return searcheableColumns.map(function (c) {
      var regex = (0, _createRegex.default)(Ember.get(c, 'filter'));
      return function (row) {
        var value = Ember.get(row, Ember.get(c, 'prop'));
        var passesRegex = true;
        if (!Ember.isEmpty(Ember.get(c, 'filter'))) {
          passesRegex = regex.test(value);
        }
        var passesCustom = true;
        if (!Ember.isEmpty(Ember.get(c, 'filterFunction'))) {
          passesCustom = Ember.get(c, 'filterFunction')(value, Ember.get(c, 'filterUsing'), row);
        }
        return passesRegex && passesCustom;
      };
    });
  }
  function filterData(data, columns, globalFilter, filterFunction, filterUsing) {
    if (Ember.isEmpty(data)) {
      return [];
    }
    if (Ember.isEmpty(columns)) {
      // bail out if there are no columns to filter
      return data;
    }
    var globalRegex = (0, _createRegex.default)(globalFilter, false, true, true);
    var columnFilters = createColumnFilters(columns);
    return data.filter(function (row) {
      var passesGeneral = true;
      if (!Ember.isEmpty(globalRegex)) {
        passesGeneral = columns.some(function (c) {
          return globalRegex.test(Ember.get(row, Ember.get(c, 'prop')));
        });
      }
      var passesColumn = true;
      if (!Ember.isEmpty(columnFilters)) {
        passesColumn = columnFilters.every(function (fn) {
          return fn(row);
        });
      }
      var passesCustom = true;
      if (!Ember.isEmpty(filterFunction)) {
        passesColumn = filterFunction(row, filterUsing);
      }
      return passesGeneral && passesColumn && passesCustom;
    });
  }
});