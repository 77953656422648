define("ember-cli-spinjs/components/ember-spinner", ["exports", "spin.js"], function (_exports, _spin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global require */
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'spinner-display',
    animation: 'spinner-line-fade-quick',
    color: '#333',
    corners: 1,
    direction: 1,
    fps: 20,
    left: '50%',
    length: 7,
    lines: 12,
    radius: 10,
    rotate: 0,
    scale: 1.0,
    shadow: false,
    speed: 1,
    top: '50%',
    width: 5,
    zIndex: 2000000000,
    position: 'absolute',
    spinner: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.configArgs = {};
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      var opts = {
        animation: this.get('animation'),
        color: this.get('color'),
        corners: this.get('corners'),
        direction: this.get('direction'),
        fps: this.get('fps'),
        left: this.get('left'),
        length: this.get('length'),
        lines: this.get('lines'),
        radius: this.get('radius'),
        rotate: this.get('rotate'),
        scale: this.get('scale'),
        shadow: this.get('shadow'),
        speed: this.get('speed'),
        top: this.get('top'),
        width: this.get('width'),
        zIndex: this.get('zIndex'),
        position: this.get('position'),
        hwaccel: true
      };
      var configArgs;
      if (this.get('config')) {
        var modulePrefix = this.emberSpinnerPrefixConfig.modulePrefix;
        var configFile = "".concat(modulePrefix, "/config/ember-spinner/").concat(this.get('config'));
        configArgs = require(configFile).default;
      }
      this.spinnerArgs = Ember.assign(opts, configArgs);
    },
    didInsertElement: function didInsertElement() {
      this.spinner = new _spin.Spinner(this.spinnerArgs).spin(this.element);
    },
    willRemoveElement: function willRemoveElement() {
      this.spinner.stop();
    }
  });
});