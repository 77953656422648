define("ember-yeti-table/components/yeti-table/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "AOTqmq8h",
    "block": "{\"symbols\":[\"@theme\",\"&attrs\",\"@trClass\",\"@parent\",\"@sortSequence\",\"@sortable\",\"&default\"],\"statements\":[[11,\"thead\"],[16,0,[32,1,[\"thead\"]]],[17,2],[12],[2,\"\\n  \"],[10,\"tr\"],[15,0,[31,[[32,3],\" \",[32,1,[\"theadRow\"]],\" \",[32,1,[\"row\"]]]]],[12],[2,\"\\n    \"],[18,7,[[30,[36,2],null,[[\"column\"],[[30,[36,1],[\"ember-yeti-table@yeti-table/thead/row/column/component\"],[[\"sortable\",\"sortSequence\",\"onClick\",\"parent\",\"theme\"],[[32,6],[32,5],[30,[36,0],[[32,0,[\"onColumnClickHeader\"]]],null],[32,4],[32,1]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/header/template.hbs"
    }
  });
});