define('ember-cli-bootstrap3-tooltip/components/twbs-button', ['exports', 'ember-cli-bootstrap3-tooltip/components/twbs-span'], function (exports, _twbsSpan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _twbsSpan.default.extend({
    attributeBindings: ['autofocus', 'disabled', 'form', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'name', 'type', 'value'],
    classNames: ['btn', 'twbs-button'],
    /**
     * This is a `button` tag.
     */
    tagName: 'button',
    twbsSpan: false // turns of the style class `twbs-span` inherited from the `twbs-span` component.
  });
});