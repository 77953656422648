define('ember-cli-bootstrap3-tooltip/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      fireEvent: function fireEvent(labelId /*, $element, component*/) {
        var _this = this;

        var $label = Ember.$(labelId);

        $label.removeClass('label-default').addClass('label-success');

        Ember.run.later(this, function () {
          $label.removeClass('label-success').addClass('label-warning');

          Ember.run.later(_this, function () {
            $label.removeClass('label-warning').addClass('label-danger');
            Ember.run.later(_this, function () {
              $label.removeClass('label-danger').addClass('label-default');
            }, 1000);
          }, 1000);
        }, 1000);
      }
    }
  });
});