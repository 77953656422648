define("ember-sortable/utils/keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isUpArrowKey = _exports.isSpaceKey = _exports.isRightArrowKey = _exports.isLeftArrowKey = _exports.isEscapeKey = _exports.isEnterKey = _exports.isDownArrowKey = _exports.SPACE_KEY_CODE = _exports.ESCAPE_KEY_CODE = _exports.ENTER_KEY_CODE = _exports.ARROW_KEY_CODES = void 0;
  var ENTER_KEY = 'Enter';
  var ESCAPE_KEY = 'Escape';
  var SPACE_KEY = 'Space';
  var ARROW_KEYS = {
    LEFT: 'ArrowLeft',
    UP: 'ArrowUp',
    RIGHT: 'ArrowRight',
    DOWN: 'ArrowDown'
  };
  var ENTER_KEY_CODE = _exports.ENTER_KEY_CODE = 13;
  var ESCAPE_KEY_CODE = _exports.ESCAPE_KEY_CODE = 27;
  var SPACE_KEY_CODE = _exports.SPACE_KEY_CODE = 32;
  var ARROW_KEY_CODES = _exports.ARROW_KEY_CODES = {
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
  };
  function createKeyTest(key, keyCode) {
    return function isKey(event) {
      return event.key === key || event.keyCode === keyCode;
    };
  }
  var isEnterKey = _exports.isEnterKey = createKeyTest(ENTER_KEY, ENTER_KEY_CODE);
  var isEscapeKey = _exports.isEscapeKey = createKeyTest(ESCAPE_KEY, ESCAPE_KEY_CODE);
  var isSpaceKey = _exports.isSpaceKey = createKeyTest(SPACE_KEY, SPACE_KEY_CODE);
  var isLeftArrowKey = _exports.isLeftArrowKey = createKeyTest(ARROW_KEYS.LEFT, ARROW_KEY_CODES.LEFT);
  var isUpArrowKey = _exports.isUpArrowKey = createKeyTest(ARROW_KEYS.UP, ARROW_KEY_CODES.UP);
  var isRightArrowKey = _exports.isRightArrowKey = createKeyTest(ARROW_KEYS.RIGHT, ARROW_KEY_CODES.RIGHT);
  var isDownArrowKey = _exports.isDownArrowKey = createKeyTest(ARROW_KEYS.DOWN, ARROW_KEY_CODES.DOWN);
});