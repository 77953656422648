define('ember-route-history/mixins/routes/route-history', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		setCurrentRoute: Ember.on('activate', function () {
			this.get('routeHistory').setCurrentRoute(this);
		})
	});
});