define("ember-concurrency-decorators/index", ["exports", "@ember-decorators/utils/decorator", "ember-concurrency", "ember-concurrency-decorators/last-value"], function (_exports, _decorator, _emberConcurrency, _lastValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keepLatestTaskGroup = _exports.keepLatestTask = _exports.enqueueTaskGroup = _exports.enqueueTask = _exports.dropTaskGroup = _exports.dropTask = void 0;
  Object.defineProperty(_exports, "lastValue", {
    enumerable: true,
    get: function get() {
      return _lastValue.default;
    }
  });
  _exports.taskGroup = _exports.task = _exports.restartableTaskGroup = _exports.restartableTask = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /**
   * This utility function assures compatibility with the Ember object model style
   * and initializer syntax required by Babel 6.
   *
   * For native classes using the method shorthand style (TypeScript & Babel 7),
   * this function will access the `value`. For legacy code it will get the value
   * from the initializer.
   *
   * // Ember object model
   * export default EmberObject.extend({
   *   @task
   *   someTask: function*() {}
   * });
   *
   * // Class syntax with initializers
   * export default class extends EmberObject {
   *   @task
   *   someTask = function*() {}
   * }
   *
   * @param desc
   * @returns {object|null}
   * @private
   */
  function extractValue(desc) {
    if (typeof desc.initializer === 'function') {
      return desc.initializer.call(undefined);
    }
    if (typeof desc.get === 'function') {
      return desc.get.call(undefined);
    }
    if (desc.value) {
      return desc.value;
    }
    return undefined;
  }
  function isTaskFunction(value) {
    return typeof value === 'function';
  }
  function isEncapsulatedTask(value) {
    return _typeof(value) === 'object' && value !== null && isTaskFunction(value.perform);
  }

  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskProperty`.
   *
   * @param desc
   * @returns {TaskProperty}
   * @private
   */
  function createTaskFromDescriptor(desc) {
    var value = extractValue(desc);
    if (isTaskFunction(value)) {
      return (0, _emberConcurrency.task)(value);
    }
    if (isEncapsulatedTask(value)) {
      return (0, _emberConcurrency.task)(value);
    }
    (false && !(false) && Ember.assert('ember-concurrency-decorators: Can only decorate a generator function as a task or an object with a generator method `perform` as an encapsulated task.'));
  }

  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskGroupProperty`.
   *
   * @param desc
   * @returns {TaskGroupProperty}
   * @private
   */
  function createTaskGroupFromDescriptor(_desc) {
    return (0, _emberConcurrency.taskGroup)();
  }

  /**
   * Applies the `options` provided using the chaining API on the given `task`.
   *
   * @param options
   * @param {TaskProperty|TaskGroupProperty} task
   * @private
   */

  function applyOptions(options, task) {
    var keys = Object.keys(options);
    for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
      var key = _keys[_i];
      var value = options[key];
      (false && !(value !== undefined) && Ember.assert("ember-concurrency-decorators: Option '".concat(key, "' is not a valid value"), value !== undefined));
      (false && !(typeof task[key] === 'function') && Ember.assert("ember-concurrency-decorators: Option '".concat(key, "' is not a valid function"), typeof task[key] === 'function'));
      if (value === true) {
        task[key]();
      } else {
        task[key](value);
      }
    }

    // The CP decorator gets executed in `createDecorator`
    return task;
  }

  /**
   * Creates a decorator function that transforms the decorated property using the
   * given `propertyCreator` and accepts an optional user provided options hash,
   * that that will be merged with the `baseOptions`.
   *
   * @param {function} propertyCreator
   * @param {object} [baseOptions={}]
   * @private
   */

  function createDecorator(propertyCreator) {
    var baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _decorator.decoratorWithParams)(function (target, key, desc) {
      var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
        _ref2 = _slicedToArray(_ref, 1),
        userOptions = _ref2[0];
      var initializer = desc.initializer,
        value = desc.value;
      delete desc.initializer;
      delete desc.value;
      return applyOptions(_objectSpread(_objectSpread({}, baseOptions), userOptions), propertyCreator(_objectSpread(_objectSpread({}, desc), {}, {
        initializer: initializer,
        value: value
      })))(target, key, desc);
    });
  }

  /**
   * Turns the decorated generator function into a task.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, `group` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @task
   *   *plainTask() {}
   *
   *   @task({ maxConcurrency: 5, keepLatest: true, cancelOn: 'click' })
   *   *taskWithModifiers() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  var task = _exports.task = createDecorator(createTaskFromDescriptor);

  /**
   * Turns the decorated generator function into a task and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  var restartableTask = _exports.restartableTask = createDecorator(createTaskFromDescriptor, {
    restartable: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  var dropTask = _exports.dropTask = createDecorator(createTaskFromDescriptor, {
    drop: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  var keepLatestTask = _exports.keepLatestTask = createDecorator(createTaskFromDescriptor, {
    keepLatest: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  var enqueueTask = _exports.enqueueTask = createDecorator(createTaskFromDescriptor, {
    enqueue: true
  });

  /**
   * Turns the decorated property into a task group.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task group. For instance `maxConcurrency` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task taskGroup } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @taskGroup({ maxConcurrency: 5 }) someTaskGroup;
   *
   *   @task({ group: 'someTaskGroup' })
   *   *someTask() {}
   *
   *   @task({ group: 'someTaskGroup' })
   *   *anotherTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  var taskGroup = _exports.taskGroup = createDecorator(createTaskGroupFromDescriptor);

  /**
   * Turns the decorated property into a task group and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  var restartableTaskGroup = _exports.restartableTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    restartable: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  var dropTaskGroup = _exports.dropTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    drop: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  var keepLatestTaskGroup = _exports.keepLatestTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    keepLatest: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  var enqueueTaskGroup = _exports.enqueueTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    enqueue: true
  });
});