define('ember-route-history/services/route-history', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		/**
   * Current route
   *
   * @property current
   * @type {String}
   */
		current: '',

		/**
   * Previous route. If there is no previous route, returns null
   *
   * @property previous
   * @type {String}
   */
		previous: Ember.computed('history.[]', function () {
			var history = this.get('history');
			var historyLength = history.get('length');

			if (!Ember.isEmpty(history) && historyLength > 1) {
				return history.objectAt(historyLength - 2);
			}

			return null;
		}),

		/**
   * Array contening the history of routes that have been visited.
   *
   * @property history
   * @type {Array}
   */
		history: Ember.A(),

		/**
   * Maximum number of entries to keep in the history.
   *
   * @property maxHistoryLength
   * @type number
   */
		maxHistoryLength: 10,

		/**
   * Pushes a route name onto the history stack.
   *
   * @method addRouteToHistory
   * @param routeName
   * @return The current history stack.
   */
		addRouteToHistory: function addRouteToHistory(routeName) {
			var maxHistoryLength = this.get('maxHistoryLength');
			var history = this.get('history');

			history.pushObject(routeName);

			if (history.get('length') > maxHistoryLength) {
				history.shiftObject();
			}

			return history;
		},


		/**
   * @method setCurrentRoute
   * @param route
   */
		setCurrentRoute: function setCurrentRoute(route) {
			var routeName = route.get('routeName');
			if (routeName !== 'loading') {
				this.set('current', routeName);
				this.addRouteToHistory(routeName);
			}
		}
	});
});