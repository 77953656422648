define('ember-content-placeholders/components/content-placeholders-text', ['exports', 'ember-content-placeholders/components/content-placeholders-base', 'ember-content-placeholders/templates/components/content-placeholders-text'], function (exports, _contentPlaceholdersBase, _contentPlaceholdersText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contentPlaceholdersBase.default.extend({
    className: 'ember-content-placeholders-text',
    classNameBindings: ['className'],

    lines: 4,
    layout: _contentPlaceholdersText.default,

    linesArray: Ember.computed('lines', function () {
      return Array.apply(null, Array(Ember.get(this, 'lines')));
    })

  });
});