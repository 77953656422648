define('ember-route-history/initializers/inject-route-history', ['exports', 'ember-route-history/services/route-history'], function (exports, _routeHistory) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.initialize = initialize;
	function initialize(application) {
		application.register('service:route-history', _routeHistory.default);
		application.inject('route', 'routeHistory', 'service:route-history');
	}

	exports.default = {
		name: 'injectRouteHistory',
		after: 'ember-data',
		initialize: initialize
	};
});