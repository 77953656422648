define('ember-content-placeholders/components/content-placeholders-heading', ['exports', 'ember-content-placeholders/components/content-placeholders-base', 'ember-content-placeholders/templates/components/content-placeholders-heading'], function (exports, _contentPlaceholdersBase, _contentPlaceholdersHeading) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contentPlaceholdersBase.default.extend({
    className: 'ember-content-placeholders-heading',
    classNameBindings: ['className'],
    layout: _contentPlaceholdersHeading.default
  });
});