define("ember-sortable/utils/coordinate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getX = getX;
  _exports.getY = getY;
  /**
    Gets the y offset for a given event.
    Work for touch and mouse events.
    @method getY
    @return {Number}
    @private
  */
  function getY(event) {
    var touches = event.changedTouches;
    var touch = touches && touches[0];
    if (touch) {
      return touch.screenY;
    } else {
      return event.clientY;
    }
  }

  /**
    Gets the x offset for a given event.
    @method getX
    @return {Number}
    @private
  */
  function getX(event) {
    var touches = event.changedTouches;
    var touch = touches && touches[0];
    if (touch) {
      return touch.screenX;
    } else {
      return event.clientX;
    }
  }
});