define("ember-yeti-table/-private/utils/sorting-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compareValues = compareValues;
  _exports.mergeSort = mergeSort;
  _exports.sortMultiple = sortMultiple;
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function merge(left, right, comparator) {
    var mergedArray = [];
    var leftIndex = 0;
    var rightIndex = 0;
    while (leftIndex < left.length && rightIndex < right.length) {
      var comparison = comparator(left[leftIndex], right[rightIndex]);
      if (comparison <= 0) {
        mergedArray.push(left[leftIndex]);
        leftIndex++;
      } else {
        mergedArray.push(right[rightIndex]);
        rightIndex++;
      }
    }
    if (leftIndex < left.length) {
      mergedArray.splice.apply(mergedArray, [mergedArray.length, 0].concat(_toConsumableArray(left.slice(leftIndex))));
    }
    if (rightIndex < right.length) {
      mergedArray.splice.apply(mergedArray, [mergedArray.length, 0].concat(_toConsumableArray(right.slice(rightIndex))));
    }
    return mergedArray;
  }

  /**
   * An implementation of the standard merge sort algorithm.
   *
   * This is necessary because we need a stable sorting algorithm that accepts
   * a general comparator. The built in sort function and Ember's sort functions
   * are not stable, and `_.sortBy` doesn't take a general comparator. Ideally
   * lodash would add a `_.sort` function whose API would mimic this function's.
   *
   * @function
   * @param {Array} array The array to be sorted
   * @param {Comparator} comparator The comparator function to compare elements with.
   * @return {Array} A sorted array
   */
  function mergeSort(array) {
    var comparator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.compare;
    if (array.length <= 1) {
      return array;
    }
    var middleIndex = Math.floor(array.length / 2);
    var leftArray = mergeSort(array.slice(0, middleIndex), comparator);
    var rightArray = mergeSort(array.slice(middleIndex), comparator);
    return merge(leftArray, rightArray, comparator);
  }
  function sortMultiple(itemA, itemB, sorts, compare) {
    var compareValue;
    var _iterator = _createForOfIteratorHelper(sorts),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _step.value,
          prop = _step$value.prop,
          direction = _step$value.direction;
        var valueA = Ember.get(itemA, prop);
        var valueB = Ember.get(itemB, prop);
        compareValue = direction === 'asc' ? compare(valueA, valueB) : -compare(valueA, valueB);
        if (compareValue !== 0) {
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return compareValue;
  }
  function isExactlyNaN(value) {
    return typeof value === 'number' && isNaN(value);
  }
  function isEmpty(value) {
    return Ember.isNone(value) || isExactlyNaN(value);
  }
  function orderEmptyValues(itemA, itemB) {
    var aIsEmpty = isEmpty(itemA);
    var bIsEmpty = isEmpty(itemB);
    if (aIsEmpty && !bIsEmpty) {
      return -1;
    } else if (bIsEmpty && !aIsEmpty) {
      return 1;
    } else if (Ember.isNone(itemA) && isExactlyNaN(itemB)) {
      return -1;
    } else if (isExactlyNaN(itemA) && Ember.isNone(itemB)) {
      return 1;
    } else {
      return 0;
    }
  }
  function compareValues(itemA, itemB) {
    if (isEmpty(itemA) || isEmpty(itemB)) {
      return orderEmptyValues(itemA, itemB);
    }
    return Ember.compare(itemA, itemB);
  }
});