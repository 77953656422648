define("ember-math-helpers/helpers/random", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.random = random;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var min = Math.min,
    max = Math.max;

  // @see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#max(0, min(MAX_DECIMALS, decimals))));
  var MAX_DECIMALS = 20;

  // 💡 Using Number.toFixed, we'll get rounding for free alongside
  // decimal precision. We'll default to whole-number rounding simply
  // by defaulting `decimals` to 0
  var DEFAULT_OPTS = {
    decimals: 0
  };

  /**
   * Executes `Math.random` with the provided upper-bounded randoms, lower- and upper-bounded randoms,
   * and the option to configure decimal precision.
   *
   * ```hbs
   * {{random}} or {{random decimals=4}}
   * ```
   *
   * ```hbs
   * {{random 42}} or {{random 42 decimals=4}}
   * ```
   *
   * ```hbs
   * {{random 21 1797}} or {{random 21 1797 decimals=4}}
   * ```
   *
   * @param {number} upperBound
   * @param {number} lowerBound
   * @param {object} { decimals } decimal precision value
   * @return {number} The random generated number
   */
  function random(params) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_OPTS,
      decimals = _ref.decimals;
    // no positional args, but only an options hash from named args
    if (_typeof(params) === 'object' && !Ember.isArray(params)) {
      decimals = typeof params.decimals !== 'undefined' ? params.decimals : DEFAULT_OPTS.decimals;
      return +Math.random().toFixed(max(0, min(MAX_DECIMALS, decimals)));
    }

    // one positional arg: treat it as an upper bound
    if (params && params.length === 1) {
      var _params = _slicedToArray(params, 1),
        upperBound = _params[0];
      return +(Math.random() * upperBound).toFixed(max(0, min(MAX_DECIMALS, decimals)));
    }

    // two positional args: use them to derive upper and lower bounds
    if (params && params.length === 2) {
      var _params2 = _slicedToArray(params, 2),
        lowerBound = _params2[0],
        _upperBound = _params2[1];

      // for convenience, swap if a higher number is accidentally passed first
      if (_upperBound < lowerBound) {
        var _ref2 = [_upperBound, lowerBound];
        lowerBound = _ref2[0];
        _upperBound = _ref2[1];
      }
      return +(lowerBound + Math.random() * (_upperBound - lowerBound)).toFixed(max(0, min(MAX_DECIMALS, decimals)));
    }

    // no positional or named args: just return Math.random() w/ default decimal precision
    return +Math.random().toFixed(max(0, min(MAX_DECIMALS, decimals)));
  }
  var _default = _exports.default = Ember.Helper.helper(random);
});